import * as React from 'react';
import { Link } from 'gatsby';

import styles from './index.module.css';

import {
  AboutWrapper,
} from './style';



const User = props => (
<div>
  <Link to={props.url}>
    <div className={styles.user}>
        <img src={props.avatar} className={styles.avatar} alt="" />
      <div className={styles.description}>
        <h2 className={styles.username}><span className={styles.themename}>{props.themename}</span>{props.username}</h2>
        <p className={styles.excerpt}>{props.excerpt}</p>
      </div>
    </div>
  </Link>
</div>
)




const useStateWithLocalStorage = localStorageKey => {
  const [value, setValue] = React.useState(
    typeof window !== 'undefined' && window.localStorage.getItem(localStorageKey)
    || ''
  );
 
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(localStorageKey, value);
    }
  }, [value]);
 
  return [value, setValue];
};

 
const App = () => {
  const [value, setValue] = useStateWithLocalStorage(
    'cityset1'
  );
 
  const onChange = event => setValue(event.target.value);
 
  return (
    <AboutWrapper>


      <User
        url="/sdgs"
        themename="SDGs"
        username="のテーマから選ぶ"
        avatar="https://source.unsplash.com/featured/?sdgs"
        excerpt="I'm Jane Doe. Lorem ipsum dolor sit amet, consectetur adipisicing elit."
      />
      <User
        url="/sdgs"
        themename="高校地理総合"
        username="の目次から選ぶ"
        avatar="https://source.unsplash.com/featured/?map"
        excerpt="I'm Bob Smith, a vertically aligned type of guy. Lorem ipsum dolor sit amet, consectetur adipisicing elit."
      />
      <User
        url="/sdgs"
        themename="高校地理探究"
        username="の目次から選ぶ"
        avatar="https://source.unsplash.com/featured/?geography"
        excerpt="I'm Bob Smith, a vertically aligned type of guy. Lorem ipsum dolor sit amet, consectetur adipisicing elit."
      />
      <User
        url="/sdgs"
        themename="出典"
        username="から選ぶ"
        avatar="https://source.unsplash.com/featured/?book"
        excerpt="I'm Bob Smith, a vertically aligned type of guy. Lorem ipsum dolor sit amet, consectetur adipisicing elit."
      />
      <User
        url="/sdgs"
        themename="新しい順"
        username="から選ぶ"
        avatar="https://source.unsplash.com/featured/?new"
        excerpt="I'm Bob Smith, a vertically aligned type of guy. Lorem ipsum dolor sit amet, consectetur adipisicing elit."
      />

    </AboutWrapper>

  );
};
 
export default App;



